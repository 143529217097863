import * as React from "react";
import { format } from "date-fns";

import { TableCell, TableRow } from "@mui/material";
// import Tools from "./Tools";
import Toggle from "../Toggle";

const ContentItem = ({
  columns,
  itemData,
  handleSelect,
  // handleDelete,
  handlePublish,
}) => (
  <TableRow
    hover
    sx={{ cursor: "pointer", height: "48px" }}
    onClick={() => {
      handleSelect(itemData._id || itemData.slug);
    }}
  >
    {columns.map((col, idx) => (
      <TableCell
        key={`${col}-${itemData._id}`}
        sx={{
          width: idx === 1 ? "40%" : "20%",
          textAlign: "left",
          padding: "0 1rem",
        }}
      >
        {col === "published" ? (
          <Toggle
            {...{
              value: itemData[col],
              handleChange: handlePublish,
              id: itemData._id,
            }}
          />
        ) : col === "last_updated" && itemData[col] ? (
          format(itemData[col], "dd MMM yy HH:mm")
        ) : (
          itemData[col]?.toString()
        )}
      </TableCell>
    ))}
    {/* <Tools
      {...{
        handleSelect,
        handleDelete,
        itemData,
        deleteDisabled: itemData.itemCount > 0,
      }}
    /> */}
  </TableRow>
);

export default ContentItem;
