import { Typography } from "@mui/material";

const baseStyles = {
  fontWeight: "600",
  margin: "2rem 0 1rem",
};

export const Text = ({ variant = "body1", sx, children }) => (
  <Typography
    variant={variant}
    sx={{
      fontSize: "1rem",
      lineHeight: "1.5rem",
      ...sx,
    }}
  >
    {children}
  </Typography>
);

export const H1 = ({ sx, children }) => (
  <Typography
    variant="h1"
    sx={{
      fontSize: "1.8rem",
      ...baseStyles,
      ...sx,
    }}
  >
    {children}
  </Typography>
);

export const H2 = ({ sx, children }) => (
  <Typography
    variant="h2"
    sx={{
      fontSize: "1.4rem",
      ...baseStyles,
      ...sx,
    }}
  >
    {children}
  </Typography>
);

export const H3 = ({ sx, children }) => (
  <Typography
    variant="h3"
    sx={{
      fontSize: "1.2rem",
      ...baseStyles,
      ...sx,
    }}
  >
    {children}
  </Typography>
);

export const H4 = ({ sx, children }) => (
  <Typography
    variant="h3"
    sx={{
      fontSize: "1rem",
      ...baseStyles,
      margin: "1rem 0 0",
      ...sx,
    }}
  >
    {children}
  </Typography>
);
