import * as React from "react";
import { Navigate, useRoutes } from "react-router-dom";

import Collections from "../pages/Collections";
import Collection from "../pages/Collection";
// import FourOhFour from "../pages/four-oh-four";

const AppRouter = () =>
  useRoutes([
    { path: "/", element: <Navigate to="/collections" replace /> },
    { path: "/collections", element: <Collections /> },
    // { path: "/collections/:collectionId", element: <Collection /> },
    {
      path: "/collections/:collectionId/items",
      element: <Collection currentTab={0} itemTabType="view" />,
    },
    {
      path: "/collections/:collectionId/utilities",
      element: <Collection currentTab={1} />,
    },
    {
      path: "/collections/:collectionId/preview",
      element: <Collection currentTab={2} subTab={0} />,
    },
    {
      path: "/collections/:collectionId/preview/subcollection",
      element: <Collection currentTab={2} subTab={1} />,
    },
    {
      path: "/collections/:collectionId/preview/widget",
      element: <Collection currentTab={2} subTab={2} />,
    },
    // {
    //   path: "/collections/:collectionId/add",
    //   element: <Collection currentTab={0} itemTabType="add" />,
    // },
    // {
    //   path: "/collections/:collectionId/settings",
    //   element: <Collection currentTab={1} />,
    // },
    // {
    //   path: "/collections/:collectionId/keys",
    //   element: <Collection currentTab={2} />,
    // },
    // {
    //   path: "/collections/:collectionId/preview",
    //   element: <Collection currentTab={4} />,
    // },
    // {
    //   path: "/collections/:collectionId/items/:itemId",
    //   element: <Collection currentTab={0} itemTabType="edit" />,
    // },

    // { path: "/not-found", element: <FourOhFour /> },
    // { path: "*", element: <Navigate to="/not-found" replace /> },
  ]);

export default AppRouter;
