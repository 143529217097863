import React from "react";
import ReactDOM from "react-dom/client";

import App from "./components/App";
import { UserProvider } from "./context/UserContext";
import { CollectionsProvider } from "./context/CollectionsContext";
import { ItemsProvider } from "./context/ItemsContext";

import "./styles/index.css";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <UserProvider>
      <CollectionsProvider>
        <ItemsProvider>
          <App />
        </ItemsProvider>
      </CollectionsProvider>
    </UserProvider>
  </React.StrictMode>
);
