import * as React from "react";
import {
  Box,
  FormControl,
  InputLabel,
  List,
  ListItem,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";

import { H3, Text } from "../../../components/common/typography";
import DivSnippet from "../../../components/DivSnippet";

import useCollectionsContext from "../../../context/CollectionsContext";
import EmbedPreview from "../../../components/EmbedPreview";

const Subcollection = ({
  previewScript,
  resetPreviewScript,
  setPreviewScriptState,
}) => {
  const [subcollection, setSubcollection] = React.useState("");

  const { current: currentColl } = useCollectionsContext();
  const { container_id, custom_fields } = currentColl;
  const { options } =
    custom_fields?.find((cf) => cf.slug === "subcollection") || {};

  React.useEffect(() => {
    if (subcollection) setPreviewScriptState(true);
  }, [setPreviewScriptState, subcollection]);

  if (!options) {
    return (
      <Box sx={{ marginTop: "2rem" }}>
        <Typography>
          You need to add a subcollection custom_field to the collection in
          order to preview this feature.
        </Typography>
      </Box>
    );
  }

  const handleChange = (ev) => {
    setSubcollection(ev.target.value);
    resetPreviewScript();
  };

  return (
    <>
      <Box sx={{ paddingTop: "1rem" }}>
        <Text>
          Adding a subcollection is the same as adding the full embed. You will
          limit the items displayed by adding an attribute to the container div.
        </Text>
        <List component="ol" marker="decimal">
          <ListItem>
            <Text>First, add the embed script below to the page.</Text>
          </ListItem>
          <ListItem>
            <Text>
              Second, create a div with the required <code>id</code> and the
              subcollection attribute <code>data-cosubcoll</code>. You can
              generate this div below by using the dropdown to pick a specific
              subcollection.
            </Text>
          </ListItem>
        </List>
      </Box>
      <H3>Generate subcollection element</H3>
      <FormControl sx={{ m: 1, minWidth: 320 }}>
        <InputLabel id="subcollection-picker">Subcollection</InputLabel>
        <Select
          labelId="subcollection-picker"
          value={subcollection}
          onChange={handleChange}
          autoWidth
          label="Subcollection"
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          {options.map((opt) => (
            <MenuItem key={`opt-${opt}`} value={opt}>
              {opt}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <DivSnippet {...{ container_id, subcollection }} />
      <EmbedPreview {...{ previewScript, container_id, subcollection }} />
    </>
  );
};

export default Subcollection;
