import * as React from "react";

import { Tabs, Box } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ padding: "0" }} p={3}>
          {children}
        </Box>
      )}
    </div>
  );
};

const TabbedContent = ({ content, currentTab = 1 }) => {
  const [activeTab, setActiveTab] = React.useState(currentTab);

  const navigate = useNavigate();

  React.useEffect(() => {
    // navigate(content[currentTab].path, { replace: true });
    setActiveTab(currentTab);
  }, [currentTab, content, navigate]);

  const handleChange = (ev, newValue) => {
    navigate(content[newValue].path, { replace: true });
    setActiveTab(newValue);
  };

  return (
    <>
      <Box position="static">
        <Tabs value={activeTab} onChange={handleChange} aria-label="tabs">
          {content.map((item) => (
            <Link
              key={`link-${item.label}`}
              style={{
                padding: "1rem",
                textDecoration: "none",
                textTransform: "uppercase",
                fontSize: "15px",
              }}
              to={item.path}
            >
              {item.label}
            </Link>
          ))}
        </Tabs>
      </Box>
      {content.map((item, idx) => (
        <TabPanel key={`panel-${idx}`} value={activeTab} index={idx}>
          {item.component}
        </TabPanel>
      ))}
    </>
  );
};

export default TabbedContent;
