import * as React from "react";
import { useNavigate } from "react-router-dom";

import LoginForm from "./LoginForm";

import magic from "../../utils/magic.utils";
import useUserContext from "../../context/UserContext";

const Login = () => {
  const [user, setUser] = useUserContext();

  const navigate = useNavigate();

  const handleLoginWithEmail = async (email) => {
    const didToken = await magic.auth.loginWithMagicLink({
      email,
    });

    if (didToken) {
      const res = await fetch(`${process.env.REACT_APP_API_URL}/api/login`, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + didToken,
        },
      });

      if (res.status) {
        const userMetadata = await magic.user.getMetadata();
        setUser(userMetadata);
        navigate("/", { replace: true });
      }
    }
  };

  // TODO: Do something with the user obj?
  return (
    <>
      <LoginForm handleLoginWithEmail={handleLoginWithEmail} user={user} />
    </>
  );
};

export default Login;
