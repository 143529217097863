import * as React from "react";
import { useParams } from "react-router-dom";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
} from "@mui/material";
import { MdExpandMore } from "react-icons/md";

import TabbedContent from "../../../components/common/TabbedContent";
import { H3, Text } from "../../../components/common/typography";
import Subcollection from "./Subcollection";
import Widget from "./Widget";
import EmbedCode from "../../../components/EmbedCode";
import Embed from "./Embed";

const embedUrl = process.env.REACT_APP_EMBED_URL;

const initialState = { js: null, css: null };

const Preview = ({ subTab: currentTab = 0 }) => {
  const { collectionId } = useParams();
  const [previewScript, setPreviewScript] = React.useState(initialState);

  const resetPreviewScript = () => {
    setPreviewScript(initialState);
  };

  const setPreviewScriptState = (toggle) => {
    setPreviewScript({
      ...(toggle
        ? {
            js: <script src={`${embedUrl}/${collectionId}.js`} />,
            css: (
              <link rel="stylesheet" href={`${embedUrl}/${collectionId}.css`} />
            ),
          }
        : {}),
    });
  };

  React.useEffect(() => {
    if (currentTab) {
      resetPreviewScript();
    }
  }, [currentTab]);

  return (
    <Box sx={{ marginTop: "2rem" }}>
      <Box
        sx={{
          paddingBottom: "2rem",
        }}
      >
        <Text>
          With the embed script, you can currently create three different
          experiences, the full embed that showcases all items in a collection,
          an full-featured embed that showcases only a subcollection of items,
          and a streamlined widget that showcases a limited number of items.
          Check out the tabs below for a preview and for a description on how to
          set each one up on your site.
        </Text>
        <Accordion
          sx={{
            paddingBottom: "1rem",
          }}
        >
          <AccordionSummary
            expandIcon={<MdExpandMore />}
            aria-controls="added-content"
            id="added-header"
          >
            <H3 sx={{ margin: "1rem 0 0" }}>Embed Script (click to view)</H3>
          </AccordionSummary>
          <AccordionDetails>
            <EmbedCode collectionId={collectionId} />
          </AccordionDetails>
        </Accordion>
      </Box>
      <TabbedContent
        {...{
          currentTab,
          content: [
            {
              label: "Embed Preview",
              path: `/collections/${collectionId}/preview`,
              component: (
                <Embed {...{ previewScript, setPreviewScriptState }} />
              ),
            },
            {
              label: "Subcollection Preview",
              path: `/collections/${collectionId}/preview/subcollection`,
              component: (
                <Subcollection
                  {...{
                    collectionId,
                    previewScript,
                    resetPreviewScript,
                    setPreviewScriptState,
                  }}
                />
              ),
            },
            {
              label: "Widget Preview",
              path: `/collections/${collectionId}/preview/widget`,
              component: (
                <Widget
                  {...{
                    collectionId,
                    previewScript,
                    resetPreviewScript,
                    setPreviewScriptState,
                  }}
                />
              ),
            },
          ],
        }}
      />
    </Box>
  );
};

export default Preview;
