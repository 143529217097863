import React from "react";
import { FilePond } from "react-filepond"; // https://pqina.nl/filepond/docs/
import { Box, Container, Typography } from "@mui/material";

import "filepond/dist/filepond.min.css";
import CustomButton from "./common/CustomButton";
import ImportSummary from "./ImportSummary";

const API_URL = `${process.env.REACT_APP_API_URL}/v2/bulk`;

const ImportExport = ({ collectionId }) => {
  const [files, setFiles] = React.useState([]);
  const [importResult, setImportResult] = React.useState([]);

  const handleExport = async () => {
    fetch(`${API_URL}/${collectionId}`, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "text/csv",
      },
    })
      .then((response) => response.blob())
      .then((blob) => {
        const now = Date.now("YYYYMMDD-HHmmss");
        const date = new Date(now).toISOString();
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${collectionId}-${date}.csv`);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      });
  };

  return (
    <>
      <Container>
        <CustomButton
          variant="contained"
          onClick={handleExport}
          label=" Export all Items"
        />

        <Box
          sx={{
            border: "3px dashed #ccc",
            padding: "2rem 2rem 1rem",
            margin: "2rem 0 2rem",
          }}
        >
          <Box sx={{ margin: "0 0 1rem" }}>
            <Typography>
              At the moment, the import feature is still in beta. In order to
              minimize errors, it is better to export your data first and use a
              copy of that csv and import an updated copy of that file below.
            </Typography>
            <Typography>
              We recommend removing all items that you do not want to edit from
              the file. Keeping only the new and/or updated items. The server
              will overwrite all items in the file, even if they are identical.
              The server WILL NOT delete any items.
            </Typography>
            <Typography>
              Do not remove the id of an existing item from the file. The server
              uses that to know whether to update an item or add a new one. New
              items won't have a value in there.{" "}
              <em>
                DO NOT manually add a value to the <code>_id</code> column.
              </em>
            </Typography>
          </Box>
          <FilePond
            {...{
              files,
              name: "csvfile",
              maxFiles: 1,
              maxFileSize: "3MB",
              onupdatefiles: setFiles,
              labelIdle:
                'Drag & Drop your files or <span class="filepond--label-action">Browse</span>',
              credits: false,
              server: {
                url: `${API_URL}/${collectionId}`,
                process: {
                  onload: (res) => {
                    setImportResult(JSON.parse(res).data);
                  },
                },
              },
            }}
          />
        </Box>
      </Container>
      <ImportSummary importResult={importResult} />
    </>
  );
};

export default ImportExport;
