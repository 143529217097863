import * as React from "react";
import { Helmet } from "react-helmet";

import { H3 } from "./common/typography";

const EmbedPreview = ({
  previewScript = {},
  container_id,
  widget_class,
  query,
  limit,
  items,
  subcollection,
}) => (
  <>
    {previewScript?.js ? (
      <>
        <Helmet>
          {previewScript.js}
          {previewScript.css}
        </Helmet>
        <H3>Preview</H3>
        <div className="givinglab-embed-wrapper">
          <div
            {...{ ...(container_id ? { id: container_id } : {}) }}
            {...{ ...(widget_class ? { className: widget_class } : {}) }}
            {...{ ...(query ? { "data-wdg-query": query } : {}) }}
            {...{ ...(limit ? { "data-wdg-limit": limit } : {}) }}
            {...{ ...(items ? { "data-wdg-items": items } : {}) }}
            {...{
              ...(subcollection ? { "data-cosubcoll": subcollection } : {}),
            }}
          />
        </div>
      </>
    ) : null}
  </>
);

export default EmbedPreview;
