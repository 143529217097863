import * as React from "react";
import EmbedPreview from "../../../components/EmbedPreview";
import useCollectionsContext from "../../../context/CollectionsContext";
import { Box, Button } from "@mui/material";

const Embed = ({ previewScript, setPreviewScriptState }) => {
  const { current: currentColl } = useCollectionsContext();
  const { container_id } = currentColl;

  return (
    <Box sx={{ paddingTop: "1rem" }}>
      {!previewScript?.js ? (
        <Button
          variant="outlined"
          onClick={() => {
            setPreviewScriptState();
            setPreviewScriptState(true);
          }}
        >
          Render Preview
        </Button>
      ) : (
        <EmbedPreview {...{ container_id, previewScript }} />
      )}
    </Box>
  );
};

export default Embed;
