import { useEffect } from "react";
import { getItems } from "../utils/fetch.utils";
import useItemsContext from "../context/ItemsContext";

export default function useFetchItems(searchTerm, pagination, collectionId) {
  const { setItems } = useItemsContext();

  const strLength = searchTerm.length || 0;

  useEffect(() => {
    if (!collectionId) return;

    // do not initiate a search if less than three characters
    if (strLength > 0 && strLength < 3) return;

    const fetchAndUpdate = async () => {
      const items = await getItems(searchTerm, pagination, collectionId);
      setItems(items);
    };

    fetchAndUpdate();
  }, [strLength, searchTerm, pagination, collectionId, setItems]);
}
