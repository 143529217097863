import * as React from "react";
import { useNavigate } from "react-router-dom";
import { Container } from "@mui/material";

// import AddCollectionModal from "../../components/AddCollection";
import DataTable from "../../components/common/DataTable";
import { H2 } from "../../components/common/typography";

import useFetchCollections from "../../hooks/use-fetch-collections.hook";
import useCollectionsContext from "../../context/CollectionsContext";

const initialState = {
  _id: "",
  name: "",
  // itemCount: "",
  last_updated: "",
  // domain: "",
  // published: false,
};

const Collections = () => {
  const {
    count,
    documents,
    // handleAdd,
    // handleDelete,
    // handlePublish,
    handleSelect,
  } = useCollectionsContext();
  // const [modalVisible, setModalVisible] = React.useState(false);
  const [searchTerm, setSearchTerm] = React.useState("");
  const [pagination, setPagination] = React.useState({ page: 0, limit: 10 });

  const navigate = useNavigate();

  useFetchCollections(searchTerm, pagination);

  // const handleSubmit = async (data) => {
  //   const resp = await handleAdd(data);
  //   if (resp._id) setModalVisible(false);
  // };

  return (
    <>
      {/* <AddCollectionModal
        {...{
          open: modalVisible,
          onClose: () => setModalVisible(false),
          onSubmit: handleSubmit,
        }}
      /> */}
      <Container>
        <H2>Collections</H2>
        <DataTable
          {...{
            type: "collections",
            initialState,
            documents: documents?.sort((a, b) => a.name.localeCompare(b.name)),
            count,
            searchTerm,
            setSearchTerm,
            pagination,
            setPagination,
            // addItem: () => setModalVisible(true),
            handleSelect: (_id) => {
              handleSelect(_id);
              navigate(`/collections/${_id}/items`, { replace: true });
            },
            // handleDelete,
            // handlePublish,
          }}
        />
      </Container>
    </>
  );
};

export default Collections;
