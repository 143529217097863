import React from "react";
// import { useParams } from "react-router-dom";

import { Box } from "@mui/material";

// import Item from "./Item";
// import DataTable from "../../../components/common/DataTable";
// import AddItemModal from "../../../components/AddItem";
import { H3 } from "../../../components/common/typography";

import useItemsContext from "../../../context/ItemsContext";
import useFetchItems from "../../../hooks/use-fetch-items.hook";
import ImportExport from "../../../components/importExport";

// const initialState = {
//   // _id: "",
//   title: "",
//   slug: "",
//   // published: false,
// };

const styling = {
  section: {
    marginTop: "1rem",
    paddingBottom: "2rem",
    borderBottom: "1px solid #eaeaea",
  },
};
const Items = ({ collectionId, /* currentColl,*/ itemTabType = "view" }) => {
  // const { itemId } = useParams();

  const { count /* documents  handleAdd, handleDelete */ } = useItemsContext();
  // const [modalVisible, setModalVisible] = React.useState(false);
  const [searchTerm] = React.useState("");
  const [pagination] = React.useState({ page: 0, limit: 10 });

  // const navigate = useNavigate();

  useFetchItems(searchTerm, pagination, collectionId);

  // const handleAddItem = async (data) => {
  //   const resp = await handleAdd(collectionId, data);
  //   if (resp._id) setModalVisible(false);
  // };

  // const handleDeleteItem = async (itemId) => {
  //   await handleDelete(collectionId, itemId);
  // };

  // const handleSelect = (itemId) => {
  //   navigate(`/collections/${collectionId}/items/${itemId}`, { replace: true });
  // };

  return (
    <>
      {/* <AddItemModal
        {...{
          open: modalVisible,
          onClose: () => setModalVisible(false),
          onSubmit: handleAddItem,
          currentColl,
        }}
      /> */}

      {/* {itemTabType === "edit" && itemId && (
        <Item
          {...{
            title: "Edit Item",
            description: "keep on editing...",
            currentColl,
            itemId,
          }}
        />
      )} */}
      {itemTabType === "view" && (
        // <>
        //   <DataTable
        //     {...{
        //       type: "items",
        //       initialState,
        //       documents,
        //       count,
        //       searchTerm,
        //       setSearchTerm,
        //       pagination,
        //       setPagination,
        //       // addItem: () => setModalVisible(true),
        //       // handleDelete: handleDeleteItem,
        //       // handleSelect,
        //     }}
        //   />
        <>
          <Box sx={styling.section}>
            <H3>Information</H3>
            <ul>
              <li>Currently {count} items in the database.</li>
            </ul>
          </Box>
          <Box sx={styling.section}>
            <H3>Import / Export</H3>
            <ImportExport {...{ collectionId }} />
          </Box>
          <div id="givinglab-embed"></div>
        </>
        // </>
      )}
    </>
  );
};

export default Items;
