import { Box, Button, List, ListItem } from "@mui/material";
import * as React from "react";
import { Text } from "../../../components/common/typography";
import { CustomTextField } from "../../../components/common/Form/FormItems";
import DivSnippet from "../../../components/DivSnippet";
import useCollectionsContext from "../../../context/CollectionsContext";
import EmbedPreview from "../../../components/EmbedPreview";

const Widget = ({ previewScript, setPreviewScriptState }) => {
  const [queryValue, setQueryValue] = React.useState("");
  const [limitValue, setLimitValue] = React.useState("");
  const [itemsValue, setItemsValue] = React.useState("");
  const [showPreview, setShowPreview] = React.useState(false);

  const { current: currentColl } = useCollectionsContext();
  const { widget_class } = currentColl;

  // React.useEffect(() => {
  //   if (queryValue || itemsValue) setPreviewScriptState();
  // }, [setPreviewScriptState, queryValue, itemsValue]);

  return (
    <>
      <Box sx={{ paddingTop: "1rem" }}>
        <Text>
          Adding a widget is the same as adding the full embed, or a
          subcollection, but with different attributes.
        </Text>
        <br />
        <Text>
          First, add the embed script below to the page. Second, create a div
          with the required <code>class</code> as set in the database. To enable
          a widget, you will need to include a query (
          <code>data-wdg-query</code>), or a list of items (
          <code>data-wdg-items</code>) to render. You can generate this div
          below by entering values in the provided form. When providing a query,
          you can also provide a limit to the number of items to render with{" "}
          <code>data-wdg-limit</code>. The default is 5.
        </Text>
        <List component="ol" marker="decimal">
          <ListItem>
            <Text>
              Query (<code>data-wdg-query</code>): A URL query string that
              starts with a question mark. For example, <code>?category=1</code>
              .
            </Text>
          </ListItem>
          <ListItem>
            <Text>
              Limit (<code>data-wdg-limit</code>): The number of items to render
              from the query.
            </Text>
          </ListItem>
          <ListItem>
            <Text>
              Items (<code>data-wdg-items</code>): A comma-separated list of
              item IDs.
            </Text>
          </ListItem>
        </List>
        <ListItem>
          <Text>
            If you provide a list of items, the query and the limit will be
            ignored.
          </Text>
        </ListItem>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          margin: "2rem 0 05.rem",
        }}
      >
        <CustomTextField
          {...{
            item: { label: "Query", name: "query" },
            value: queryValue,
            placeholder: "Add URL query string (starts with ?) here...",
            handleChange: (ev) => {
              setQueryValue(ev.target.value);
              setShowPreview(false);
              setPreviewScriptState(false);
            },
            sx: { marginRight: "1rem" },
          }}
          sx={{ marginRight: "1rem" }}
        />
        <CustomTextField
          {...{
            item: { label: "Limit", name: "limit" },
            value: limitValue,
            handleChange: (ev) => {
              setLimitValue(ev.target.value);
              setShowPreview(false);
              setPreviewScriptState(false);
            },
          }}
        />
      </Box>
      <CustomTextField
        {...{
          item: { label: "Items", name: "items" },
          placeholder: "Add a comma-separated list of item IDs here.",
          value: itemsValue,
          handleChange: (ev) => {
            setItemsValue(ev.target.value);
            setShowPreview(false);
            setPreviewScriptState(false);
          },
        }}
      />

      <Box sx={{ padding: "2rem 0" }}>
        {showPreview ? (
          <>
            <DivSnippet
              {...{
                widget_class,
                query: queryValue,
                limit: limitValue,
                items: itemsValue,
              }}
            />
          </>
        ) : (
          <Button
            variant="outlined"
            onClick={() => {
              setShowPreview(true);
              setPreviewScriptState(true);
            }}
          >
            Render Preview
          </Button>
        )}
        <EmbedPreview
          {...{
            previewScript,
            widget_class,
            query: queryValue,
            limit: limitValue,
            items: itemsValue,
          }}
        />
      </Box>
    </>
  );
};

export default Widget;
