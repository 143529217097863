import * as React from "react";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
} from "@mui/material";

import ContentItem from "./ContentItem";

const HIDDEN_COLS = ["type"];

const ContentTable = ({
  columns,
  data,
  handleDelete,
  pagination,
  setPagination,
  count,
  handleSelect,
  handlePublish,
}) => {
  const filteredColumns = columns.filter((col) => !HIDDEN_COLS.includes(col));

  const handleChangePage = (ev, newPage) => {
    setPagination({ ...pagination, page: newPage });
  };

  const handleChangeRowsPerPage = (event) => {
    setPagination({
      ...pagination,
      page: 0,
      limit: parseInt(event.target.value, 10),
    });
  };

  return (
    <>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              {filteredColumns.map((col, idx) => (
                <TableCell
                  key={`col-${col}`}
                  sx={{
                    //   width: idx !== 0 ? "50%" : "25%",
                    //   textAlign: idx !== 0 ? "center" : "left",
                    //   padding: "0 1rem .5rem",
                    fontWeight: "600",
                    textTransform: "capitalize",
                  }}
                >
                  {col}
                </TableCell>
              ))}
              {/* <TableCell
                key={`col-tools`}
                sx={{
                  width: "25%",
                  // textAlign: "center",
                  padding: "0 1rem .5rem",
                  fontWeight: "600",
                  textTransform: "capitalize",
                }}
              >
                tools
              </TableCell> */}
            </TableRow>
          </TableHead>
          <TableBody>
            {data &&
              data.map((item) => (
                <ContentItem
                  {...{
                    key: item._id || item.slug,
                    columns: filteredColumns,
                    itemData: item,
                    handleDelete: handleDelete,
                    handleSelect: handleSelect,
                    handlePublish: handlePublish,
                  }}
                />
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      {count && pagination && (
        <TablePagination
          component="div"
          count={count}
          page={pagination.page}
          onPageChange={handleChangePage}
          rowsPerPage={pagination.limit}
          onRowsPerPageChange={handleChangeRowsPerPage}
          sx={{
            margin: "1rem 0",
            display: "flex",
            justifyContent: "center",
          }}
        />
      )}
    </>
  );
};

export default ContentTable;
