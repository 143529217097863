import { useEffect } from "react";
import useCollectionsContext from "../context/CollectionsContext";
import { getCollections } from "../utils/fetch.utils";

export default function useFetchCollections(searchTerm, pagination) {
  const { setCollections } = useCollectionsContext();

  const strLength = searchTerm.length || 0;

  useEffect(() => {
    // do not initiate a search if less than three characters
    if (strLength > 0 && strLength < 3) return;

    const fetchAndUpdate = async () => {
      const collections = await getCollections(searchTerm, pagination);
      setCollections({ documents: collections.data });
    };

    fetchAndUpdate();
  }, [strLength, searchTerm, pagination, setCollections]);
}
