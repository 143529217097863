import * as React from "react";
import { CodeEditor } from "./common/Form/FormItems";
import { Box, Button } from "@mui/material";

const DivSnippet = ({
  container_id,
  items,
  limit,
  query,
  subcollection,
  widget_class,
}) => {
  const id = container_id ? ` id="${container_id}"` : "";
  const classAttr = widget_class ? ` class="${widget_class}"` : "";
  const itemsAttr = items ? ` data-wdg-items="${items}"` : "";
  const queryAttr = query ? ` data-wdg-query="${query}"` : "";
  const limitAttr = limit ? ` data-wdg-limit="${limit}"` : "";
  const subcollectionAttr = subcollection
    ? ` data-cosubcoll="${subcollection}"`
    : "";

  const snippet = `<div ${id}${classAttr}${itemsAttr}${queryAttr}${limitAttr}${subcollectionAttr}></div>`;

  const handleClick = () => {
    if (!navigator.clipboard) {
      document.execCommand("copy");
    } else {
      navigator.clipboard.writeText(snippet);
    }
  };

  return (
    <>
      <CodeEditor
        readOnly
        {...{
          "data-form-id": "embed_code",
          value: snippet,
          height: "100px",
          options: {
            showLineNumbers: true,
            tabSize: 2,
          },
        }}
      />
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          margin: "-4rem 0 0",
        }}
      >
        <Button variant="contained" onClick={() => handleClick()}>
          Copy div
        </Button>
      </Box>
    </>
  );
};

export default DivSnippet;
