export const getEmbedCode = (url, id) => {
  return [
    `<script>`,
    `  (function(d,h,o){var e=document.head,`,
    `  c=document.createElement('link'),j=document.createElement('script');`,
    `  c.href=h+'/'+o+'.css';c.rel='stylesheet';e.append(c);j.src=h+'/'+o+'.js';e.append(j);`,
    `  })(document,'${url}','${id}')`,
    `</script>`,
  ].join("\r\n");
};
