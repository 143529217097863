import { Container, Typography } from "@mui/material";

const style = {
  wrapper: {
    border: "6px dashed #eee",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "4rem 2rem",
  },
  message: { color: "#808080", fontSize: "24px", fontWeight: "600" },
};
const EmptyTable = () => (
  <Container sx={style.wrapper}>
    <Typography sx={style.message}>I've got nothing.</Typography>
  </Container>
);

export default EmptyTable;
