import * as React from "react";
import AceEditor from "react-ace";
import "ace-builds/src-noconflict/ext-language_tools";
import "ace-builds/src-noconflict/mode-html";
import "ace-builds/webpack-resolver";
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  TextField,
  Select,
  Box,
} from "@mui/material";

export const CustomTextField = ({
  item: { label, name, description, required },
  value,
  handleChange,
  parent,
  placeholder,
  multiline,
  rows = 3,
  sx = {},
}) => (
  <TextField
    fullWidth
    {...{
      required,
      label,
      placeholder,
      value: value || "",
      variant: "outlined",
      sx: { margin: "1rem 0 0", ...sx },
      onChange: (ev) => handleChange(ev, name, parent),
      helperText: description,
      margin: "normal",
    }}
    {...(multiline ? { multiline, rows } : {})}
  />
);

export const CustomSelectField = ({
  item: { label, name, description, required, options },
  handleChange,
  value,
  parent,
}) => (
  <FormControl
    fullWidth
    sx={{
      margin: "1rem 0 0",
      lineHeight: "1rem",
    }}
  >
    <InputLabel>{label}</InputLabel>
    <Select
      {...{
        required,
        label,
        value: value || "",
        onChange: (ev) => handleChange(ev, name, parent),
      }}
    >
      {options.map((opt) => (
        <MenuItem key={`opt-${opt}`} value={opt}>
          {opt}
        </MenuItem>
      ))}
    </Select>
    <FormHelperText>{description}</FormHelperText>
  </FormControl>
);

export const CodeEditor = ({
  item = {},
  handleChange,
  value,
  readOnly = false,
  options = {},
  height = "400px",
}) => {
  const { description, label, name } = item;

  return (
    <Box sx={{ margin: "1rem 0" }}>
      {label && <InputLabel htmlFor={name}>{label}</InputLabel>}
      {description && <FormHelperText>{description}</FormHelperText>}
      <AceEditor
        highlightActiveLine
        {...{
          placeholder: "Full item description here...",
          readOnly,
          name,
          mode: "html",
          theme: "clouds",
          fontSize: 15,
          value,
          onLoad: () => console.log("Loaded!"),
          ...(handleChange ? { onChange: (ev) => handleChange(ev, name) } : {}),
          width: "100%",
          setOptions: { options },
          height,
          debounceChangePeriod: 500,
          style: {
            border: "1px solid rgba(0, 0, 0, 0.23)",
            borderRadius: "4px",
            margin: ".5rem 0",
          },
        }}
      />
    </Box>
  );
};
