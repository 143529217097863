import * as React from "react";
import { CodeEditor } from "./common/Form/FormItems";
import { getEmbedCode } from "../utils/embedCode";
import { Box, Button } from "@mui/material";

const embedUrl = process.env.REACT_APP_EMBED_URL;

const EmbedCode = ({ collectionId }) => {
  const handleClick = (_id) => {
    const value = getEmbedCode(embedUrl, _id);
    if (!navigator.clipboard) {
      document.execCommand("copy");
    } else {
      navigator.clipboard.writeText(value);
    }
  };

  return (
    <>
      <CodeEditor
        readOnly
        {...{
          "data-form-id": "embed_code",
          value: getEmbedCode(embedUrl, collectionId),
          height: "200px",
          options: {
            showLineNumbers: true,
            tabSize: 2,
          },
        }}
      />
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          margin: "-4rem 0 0",
        }}
      >
        <Button variant="contained" onClick={() => handleClick(collectionId)}>
          Copy Code Snippet
        </Button>
      </Box>
    </>
  );
};

export default EmbedCode;
