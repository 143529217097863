const fetchData = async (path, method, body) => {
  try {
    return await fetch(`${process.env.REACT_APP_API_URL}/v2${path}`, {
      method,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      credentials: "include",
      ...(body ? { body: JSON.stringify(body) } : {}),
    })
      .then(async (response) => response.json())
      .then((json) => json);
  } catch (err) {
    console.log("API ERROR", err);
    // TODO: add something to notify the user of an api error
  }
};

const buildQuery = (searchTerm, { page, limit }) => {
  return `?${searchTerm ? `q=${searchTerm}&` : ""}page=${
    page + 1
  }&limit=${limit}`;
};

// public endpoints.....................
export const getItems = async (searchTerm, pagination, collectionId) => {
  return await fetchData(
    `/collections/${collectionId}/items${buildQuery(searchTerm, pagination)}`,
    "GET"
  );
};

export const getItem = async (collectionId, itemId) => {
  return await fetchData(`/collections/${collectionId}/items/${itemId}`, "GET");
};

// private endpoints.....................
export const getCollections = async (searchTerm, pagination) =>
  await fetchData(`/collections${buildQuery(searchTerm, pagination)}`, "GET");

export const getCollection = async (id) =>
  await fetchData(`/collections/${id}`, "GET");

export const addCollection = async (name) =>
  await fetchData(`/collections`, "POST", { name, published: false });

export const addItem = async (collectionId, data) => {
  return await fetchData(`/collections/${collectionId}/items`, "POST", data);
};

export const updateCollection = async (id, data) => {
  return await fetchData(`/collections/${id}`, "PATCH", data);
};

export const updateItem = async (collectionId, itemId, data) =>
  await fetchData(
    `/collections/${collectionId}/items/${itemId}`,
    "PATCH",
    data
  );

export const deleteCollection = async (collectionId) =>
  await fetchData(`/collections/${collectionId}`, "DELETE");

export const deleteItem = async (collectionId, itemId) =>
  await fetchData(`/collections/${collectionId}/items/${itemId}`, "DELETE");

export const regenerateOptions = async (collectionId) =>
  await fetchData(`/utilities/${collectionId}/regenerate-options`, "PATCH");

export const getImages = async (searchTerm, pagination, collectionId) =>
  await fetchData(
    `/collections/${collectionId}/images${buildQuery(searchTerm, pagination)}`,
    "GET"
  );

export const getImage = async (collectionId, imageId) =>
  await fetchData(`/collections/${collectionId}/images/${imageId}`, "GET");

export const addImage = async (collectionId, data) =>
  await fetchData(`/collections/${collectionId}/images`, "POST", data);
