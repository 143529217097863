import * as React from "react";
import { Box, FormControl, FormControlLabel, Switch } from "@mui/material";

const Toggle = ({ value = false, label, handleChange, id }) => (
  <Box
    sx={{
      display: "flex",
      alignItems: "center",
      margin: "0 0 0 1.5rem",
    }}
  >
    <FormControl component="fieldset">
      <FormControlLabel
        {...{
          checked: value,
          control: <Switch />,
          onChange: () => handleChange(!value, id),
          ...(Boolean(label)
            ? { label: `${label}: `, labelPlacement: "start" }
            : {}),
        }}
      />
    </FormControl>
  </Box>
);

export default Toggle;
