import * as React from "react";

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@mui/material";
import CustomButton from "../common/CustomButton";

const LoginForm = ({ handleLoginWithEmail }) => {
  const [email, setEmail] = React.useState("");
  const [disabled, setDisabled] = React.useState(true);

  React.useEffect(() => {
    setDisabled(
      !email.match(
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
      )
    );
  }, [setDisabled, email]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    handleLoginWithEmail(email);
  };

  return (
    <Dialog open={true} onClose={handleSubmit} aria-labelledby="Login Form">
      <DialogTitle>Login Form</DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ marginBottom: "1rem" }}>
          If you're registered user, you'll receive an email with a login link.
        </DialogContentText>
        <TextField
          autoFocus
          label="Email Address"
          type="email"
          value={"" || email}
          onChange={(e) => setEmail(e.target.value)}
          fullWidth
        />
        <DialogActions>
          <CustomButton
            {...{ disabled, onClick: handleSubmit, label: "Log in!" }}
          />
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

export default LoginForm;
