// import { Button, Divider, Stack } from "@mui/material";
import ContentTable from "./ContentTable";
// import SearchBar from "./SearchBar";
import EmptyTable from "./EmptyTable";

const DataTable = ({
  initialState,
  documents,
  count,
  // searchTerm,
  // setSearchTerm,
  pagination,
  setPagination,
  // addItem,
  handleSelect,
  handleDelete,
  handlePublish,
}) => {
  // const handleSearch = (e) => {
  //   setSearchTerm(e.target.value);
  // };

  return (
    <>
      {/* <Stack
        direction="row"
        divider={<Divider orientation="vertical" flexItem />}
        spacing={2}
        sx={{ margin: "1rem 0" }}
      >
        <Button
          variant="contained"
          disabled={Boolean(!addItem)}
          onClick={addItem}
        >
          Add
        </Button>
        {setSearchTerm && (
          <SearchBar
            type={type}
            value={searchTerm}
            handleSearch={handleSearch}
          />
        )}
      </Stack> */}
      {documents?.length > 0 ? (
        <ContentTable
          {...{
            columns: Object.keys(initialState),
            data: documents,
            pagination,
            setPagination,
            count,
            handleSelect,
            handleDelete,
            handlePublish,
          }}
        />
      ) : (
        <EmptyTable />
      )}
    </>
  );
};

export default DataTable;
