import React from "react";
import {
  // Accordion,
  // AccordionSummary,
  // AccordionDetails,
  Box,
  Container,
  ListItem,
} from "@mui/material";
// import { MdExpandMore } from "react-icons/md";

import { H3, H4 } from "./common/typography";
import { countItems /* , getValuesByStatus */ } from "../utils/import-export";

const Item = ({ icon, item }) => (
  <ListItem
    sx={{ lineHeight: "1.4rem", display: "flex", alignItems: "flex-start" }}
  >
    <Box sx={{ marginRight: ".7rem" }}>{icon}</Box>
    <Box sx={{ width: "230px" }}> {item._id}</Box>
    <Box sx={{ flex: "1" }}>{item.title}</Box>
  </ListItem>
);

const getIcon = (status) => {
  switch (status) {
    case "added":
      return "🆕";
    case "updated":
      return "🔄";
    case "error":
      return "❌";
    default:
      return "🔒";
  }
};
const ImportSummary = ({ importResult }) => {
  const addedCount = countItems(importResult, "added");
  const updatedCount = countItems(importResult, "updated");
  const unchangedCount = countItems(importResult, "unchanged");
  const errorCount = countItems(importResult, "error");

  return importResult.length > 0 ? (
    <Container>
      <H3>Import Summary</H3>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          width: "70%",
        }}
      >
        <H4>{addedCount} item(s) added</H4>
        <H4>{updatedCount} item(s) updated</H4>
        <H4>{unchangedCount} item(s) unchanged</H4>
        <H4>{errorCount} item(s) with errors</H4>
      </Box>
      <Box
        sx={{
          borderTop: "1px solid #ccc",
          marginTop: "1rem",
          paddingTop: "1rem",
        }}
      >
        {importResult.map((item) => {
          return <Item icon={getIcon(item.status)} item={item} />;
        })}
      </Box>
      {/* <Accordion defaultExpanded={addedCount}>
        <AccordionSummary
          expandIcon={<MdExpandMore />}
          aria-controls="added-content"
          id="added-header"
        >
          <H4>{addedCount} item(s) added</H4>
        </AccordionSummary>
        <AccordionDetails>
          <ul>
            {getValuesByStatus(importResult, "added").map((item) => (
              <Item icon="🆕" item={item} />
            ))}
          </ul>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded={updatedCount}>
        <AccordionSummary
          expandIcon={<MdExpandMore />}
          aria-controls="updated-content"
          id="updated-header"
        >
          <H4>{updatedCount} item(s) updated</H4>
        </AccordionSummary>
        <AccordionDetails>
          <ul>
            {getValuesByStatus(importResult, "updated").map((item) => (
              <Item icon="🔄" item={item} />
            ))}
          </ul>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded={unchangedCount}>
        <AccordionSummary
          expandIcon={<MdExpandMore />}
          aria-controls="unchanged-content"
          id="unchanged-header"
        >
          <H4>{unchangedCount} item(s) unchanged</H4>
        </AccordionSummary>
        <AccordionDetails>
          <ul>
            {getValuesByStatus(importResult, "unchanged").map((item) => (
              <Item icon="🔒" item={item} />
            ))}
          </ul>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded={errorCount}>
        <AccordionSummary
          expandIcon={<MdExpandMore />}
          aria-controls="error-content"
          id="error-header"
        >
          <H4>{errorCount} errors!</H4>
        </AccordionSummary>
        <AccordionDetails>
          <ul>
            {getValuesByStatus(importResult, "error").map((item) => (
              <Item icon="❌" item={item} />
            ))}
          </ul>
        </AccordionDetails>
      </Accordion> */}
    </Container>
  ) : null;
};

export default ImportSummary;
