import * as React from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { Box } from "@mui/material";
import CustomButton from "./common/CustomButton";
import useUserContext from "../context/UserContext";

import magic from "../utils/magic.utils";

const Header = () => {
  const [user, setUser] = useUserContext();

  const navigate = useNavigate();
  const location = useLocation();

  return (
    <Box
      sx={{
        backgroundColor: "primary.dark",
        height: "66px",
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        padding: "0 2rem",
      }}
    >
      {user?.issuer && (
        <>
          {location?.pathname !== "/collections" && (
            <CustomButton
              sx={{ margin: "0 .5rem 0 0" }}
              onClick={() => navigate("/collections", { replace: true })}
              label="Back to Collections List"
            />
          )}
          <CustomButton
            {...{
              isDisabled: false,
              label: "Logout",
              sx: { margin: "0" },
              onClick: () => {
                magic.user.logout().then(() => {
                  setUser({ noAuth: true });
                });
              },
            }}
          />
        </>
      )}
    </Box>
  );
};

export default Header;
