import * as React from "react";
import { H3, H4 } from "../../components/common/typography";
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Typography,
} from "@mui/material";

import { regenerateOptions } from "../../utils/fetch.utils";
import useFetchCollection from "../../hooks/use-fetch-collection.hook";

const styles = {
  container: {
    display: "flex",
    margin: "0",
    padding: "0 !important",
  },
  box: {
    border: "1px solid #808080",
    borderRadius: "5px",
    margin: "0",
    padding: "0 1rem 1rem",
    // width: "50%",
  },
  text: {
    margin: "0 0 1rem",
  },
  field: {
    borderRight: "1px solid #808080",
    margin: "1rem 0",
    padding: "1rem",
  },
};

const Utils = ({ collectionId, currentColl }) => {
  const [showCurrentOptions, setShowCurrentOptions] = React.useState(true);
  const [regenStatus, setRegenStatus] = React.useState("idle");

  const { custom_fields } = currentColl;
  const filtersOnly = custom_fields?.filter((field) => field.isFilter);

  useFetchCollection(collectionId);

  const handleRegenerateOptions = async () => {
    try {
      setRegenStatus("loading");
      await regenerateOptions(collectionId);
    } catch (e) {
      console.error(e);
      // TODO add a toast message
    }
    setRegenStatus("success");
  };

  return (
    <>
      <H3>Utilities</H3>
      <Container sx={styles.container}>
        <Box sx={styles.box}>
          <H4>Filter Options</H4>
          <Typography sx={styles.text}>
            It is sometimes necessary to regenerate the filter options as they
            may fall out of sync after editing/adding/removing items. This
            action will remove all of the current filter options and scan all of
            the items to create new options based on existig/actual values in
            items.
          </Typography>
          <Box>
            <Button
              variant="outlined"
              onClick={() => setShowCurrentOptions(!showCurrentOptions)}
              sx={{ marginRight: "1rem", minWidth: "155px" }}
            >
              {showCurrentOptions ? "Hide" : "Show"} Current
            </Button>
            <Button
              disabled={regenStatus !== "idle"}
              variant="contained"
              onClick={handleRegenerateOptions}
              sx={{ minWidth: "155px" }}
            >
              {regenStatus === "idle" ? (
                "Regenerate"
              ) : regenStatus === "loading" ? (
                <CircularProgress size={20} />
              ) : (
                "Success"
              )}
            </Button>
          </Box>
          {showCurrentOptions ? (
            <Box sx={{ display: "flex" }}>
              {filtersOnly.map((field) => (
                <Box sx={styles.field}>
                  <Typography type="h5" fontWeight={600}>
                    {field.label}
                  </Typography>
                  <hr />
                  {field.options.map((option) => (
                    <Typography sx={styles.text}>{option}</Typography>
                  ))}
                </Box>
              ))}
            </Box>
          ) : null}
        </Box>
      </Container>
    </>
  );
};

export default Utils;
