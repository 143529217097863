import * as React from "react";

import { Box, Button } from "@mui/material";

const CustomButton = ({
  disabled,
  onClick,
  label = "Save Changes",
  sx = {},
  variant = "contained",
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        marginTop: "2rem",
        ...sx,
      }}
    >
      <Button variant={variant} disabled={disabled} onClick={onClick}>
        {label}
      </Button>
    </Box>
  );
};

export default CustomButton;
