import * as React from "react";
import { BrowserRouter as Router } from "react-router-dom";

import Header from "./Header";
import LoadingSpinner from "./common/LoadingSpinner";
import Login from "./Login";
import AppRouter from "./AppRouter";

import useUserContext from "../context/UserContext";
import magic from "../utils/magic.utils";

const App = () => {
  const [user, setUser] = useUserContext();

  React.useEffect(() => {
    setUser({ loading: true });

    // TODO: investigate why page does not relaod on login success
    magic.user.isLoggedIn().then((isLoggedIn) => {
      return isLoggedIn
        ? magic.user.getMetadata().then((userData) => {
            setUser(userData);
          })
        : setUser({ noAuth: true });
    });
  }, [setUser]);

  return (
    <Router>
      <Header />
      {user?.issuer && <AppRouter />}
      {user?.loading && <LoadingSpinner />}
      {user?.noAuth && <Login />}
    </Router>
  );
};

export default App;
